<div class="row" (click)="onCustomTap($event)">
    <div class="col-10">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="custom-title">{{ title }}</div>
        <div
            *ngIf="message && options.enableHtml"
            role="alert"
            aria-live="polite"
            class="custom-message"
            [class]="options.messageClass"
            [innerHTML]="message"></div>
        <div
            *ngIf="message && !options.enableHtml"
            role="alert"
            aria-live="polite"
            class="custom-message"
            [class]="options.messageClass"
            [attr.aria-label]="message">
            {{ message }}
        </div>
    </div>
    <div class="col-2 text-end">
        <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-sm toast-close-button">
            <svg-icon src="assets/images/common/svg/cross.svg" [svgClass]="'st-svg-14'"></svg-icon>
        </a>
    </div>
</div>
<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
