import { Injectable } from '@angular/core';
import { SharedService } from '../AngularSpecific/shared.service';
import { MainHubService } from './mainHub.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { OrganizationlistStoreService } from '../Organization/store/organizationlistStore.service';
import { PortfolioMemberModel } from '@smarttask-common/src/lib/models/Portfolio/Member/PortfolioMemberModel';
import { UpdateIconColorModel } from '@smarttask-common/src/lib/models/General/UpdateIconColorModel';
import * as signalr from '@microsoft/signalr';
import { PortfolioMemberRoleEnum } from '@smarttask-common/src/lib/models/Portfolio/Member/PortfolioMemberRoleEnum';

@Injectable({
    providedIn: 'root',
})
export class PortfolioHubService {
    private previous_organization_id: number;

    constructor(
        private sharedService: SharedService,
        private mainHubService: MainHubService,
        private organizationlistStoreService: OrganizationlistStoreService
    ) {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id) => {
            if (this.previous_organization_id != undefined && organization_id == undefined) this.unsubscribe(this.previous_organization_id);
            if (organization_id != undefined) this.subscribeToNewOrganization(organization_id);
        });

        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            let selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id: number) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected) {
            if (this.previous_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.previous_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.previous_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events

        this.mainHubService.connection.on(SharedEventNameEnum.portfolio_create, (portfolio_id) => {
            var event = new SharedEventModel();
            event.name = SharedEventNameEnum.portfolio_create;
            event.obj = {
                portfolio_id,
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_access_update,
            (portfolio_id: string, access_type: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_access_update;
                event.obj = {
                    portfolio_id,
                    access_type,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_name_update,
            (portfolio_id: string, name: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_name_update;
                event.obj = {
                    portfolio_id,
                    name,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_icon_color_update,
            (portfolio_id: string, model: UpdateIconColorModel) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_icon_color_update;
                event.obj = {
                    portfolio_id,
                    model: model,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_order_update,
            (portfolio_id: string, order_index: number) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_order_update;
                event.obj = {
                    portfolio_id,
                    order_index,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_column_upsert,
            (portfolio_id: string, column_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_column_upsert;
                event.obj = {
                    portfolio_id,
                    column_id,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_column_upsert,
            (portfolio_id: string, column_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_column_upsert;
                event.obj = {
                    portfolio_id,
                    column_id,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_column_delete,
            (portfolio_id: string, column_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_column_delete;
                event.obj = {
                    portfolio_id,
                    column_id,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_delete,
            (portfolio_id: string, user_ids: number[]) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_delete;
                event.obj = {
                    portfolio_id,
                    user_ids,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_members_add,
            (portfolio_id: string, users: PortfolioMemberModel[]) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_members_add;
                event.obj = {
                    portfolio_id,
                    users,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_member_role_update,
            (portfolio_id: string, user_id: number, role: PortfolioMemberRoleEnum) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_member_role_update;
                event.obj = {
                    portfolio_id,
                    user_id,
                    role
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_members_remove,
            (portfolio_id: string, user_ids: number[]) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_members_remove;
                event.obj = {
                    portfolio_id,
                    user_ids,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_projects_add,
            (portfolio_id: string, project_ids: string[]) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_projects_add;
                event.obj = {
                    portfolio_id,
                    project_ids,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_project_order_update,
            (portfolio_id: string, project_id: string, order_index: number) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_project_order_update;
                event.obj = {
                    portfolio_id,
                    project_id,
                    order_index
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.portfolio_project_remove,
            (portfolio_id: string, project_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.portfolio_project_remove;
                event.obj = {
                    portfolio_id,
                    project_id,
                };
                this.sharedService.broadcast(event);
            }
        );
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.portfolio_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.portfolio_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };
}
