import { ChatMessageService } from './Chat/chatMessage.service';
import { GroupHubService } from './SignalRHubs/groupHub.service';
import { MainHubService } from '@smarttask-app/src/app/services/SignalRHubs/mainHub.service';
import { AuthService } from '@smarttask-app/src/app/services/auth.service';
import { Injectable, Inject, forwardRef, Injector } from '@angular/core';
import { Globals } from '@smarttask-common/src/lib/services/globals';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '@smarttask-app/src/app/services/AngularSpecific/shared.service';
import { Deferred } from '@smarttask-common/src/lib/services/AngularSpecific/Deferred';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { InitializeTypeEnum } from '@smarttask-common/src/lib/models/Initialize/InitializeTypeEnum';
import { environment } from 'environments/environment.prod';
import mixpanel from 'mixpanel-browser';
import { OrganizationService } from './Organization/organization.service';
import { OrganizationlistStoreService } from './Organization/store/organizationlistStore.service';
import { UserService } from './User/user.service';
import { ProjectUserService } from './Project/projectUser.service';
import { GroupUserService } from './Group/groupUser.service';
import { OrganizationUserService } from './OrganizationUser/organizationUser.service';
import { ConfigService } from './config.service';
import { SessionService } from './Session/session.service';
import { TimeTrackingService } from './TimeTracking/timeTracking.service';
import { NotificationService } from './Notification/notification.service';
import { TitleService } from './title.service';
import { UsageService } from './Internal/usage.service';
import { ChatChannelService } from './Chat/chatChannel.service';
import { ChatHubService } from './SignalRHubs/chatHub.service';
import { ContactViewHubService } from './SignalRHubs/contactViewHub.service';
import { CustomFieldHubService } from './SignalRHubs/customFieldHub.service';
import { DiscussionsHubService } from './SignalRHubs/discussionHub.service';
import { FormHubService } from './SignalRHubs/formHub.service';
import { OrganizationHubService } from './SignalRHubs/organizationHub.service';
import { OrganizationUserHubService } from './SignalRHubs/organizationUserHub.service';
import { PortfolioHubService } from './SignalRHubs/portfolioHub.service';
import { ProjectHubService } from './SignalRHubs/projectHub.service';
import { RecordHubService } from './SignalRHubs/recordHub.service';
import { ReportHubService } from './SignalRHubs/reportHub.service';
import { ViewHubService } from './SignalRHubs/viewHub.service';
import { CallHubService } from './SignalRHubs/callHub.service';
import { CallService } from './CRM/Call/call.service';
@Injectable({
    providedIn: 'root',
})
export class InitializeService {
    is_done = false; //Tells `appLoading` directive to remove the loading screen
    result_promise: Promise<any>;
    no_organizations = false;
    sessions = 0;

    constructor(
        private injector: Injector,
        private globals: Globals,
        private authService: AuthService,
        private sharedService: SharedService,
        private organizationService: OrganizationService,
        private userService: UserService,
        private organizationlistStoreService: OrganizationlistStoreService,        
        private mainHubService: MainHubService,
        private configService: ConfigService,

        private callService: CallService,
        private chatChannelService: ChatChannelService,
        private chatMessageService: ChatMessageService,
        private groupUserService: GroupUserService,
        private notificationService: NotificationService,
        private organizationUserService: OrganizationUserService,
        private projectUserService: ProjectUserService,
        private sessionService: SessionService,
        private timeTrackingService: TimeTrackingService,
        private titleService: TitleService,
        private usageService: UsageService,        
        
        private callHubService: CallHubService,
        private chatHubService: ChatHubService,
        private contactViewHubService: ContactViewHubService,
        private customFieldHubService: CustomFieldHubService,
        private discussionsHubService: DiscussionsHubService,
        private formHubService: FormHubService,
        private groupHubService: GroupHubService,
        private organizationHubService: OrganizationHubService,
        private organizationUserHubService: OrganizationUserHubService,
        private portfolioHubService: PortfolioHubService,
        private projectHubService: ProjectHubService,
        private recordHubService: RecordHubService,
        private reportHubService: ReportHubService,
        private viewHubService: ViewHubService,
    ) {}

    load = () => {
        if (window.location.href.includes('error')) {
            return Promise.resolve();
        }
        this.result_promise = this.initialize();
        return this.result_promise;
    };

    // Initialize Function Responsibilties:
    // - Get configuration
    // - Get all authentication information for the logged user
    // - Get default organization for the logged user. While load all the organizations without blocking
    // - Initiate Webpush, session on success
    initialize = (): Promise<any> => {
        let deferred = new Deferred<number | string>();

        if (document.location.hostname.indexOf('localhost') == -1 || environment.production != true) {
            //Only load mixpanel in production
            mixpanel.init(this.globals.mixpanel_project_token, {
                loaded: () => {
                    this.globals.mixpanel_loaded = true;
                },
            });
        } else {
            console.log('Mixpanel not loaded, since its in testing mode');
        }

        let promise1 = this.dummyPromise()
            .then(this.configService.getConfiguration)
            .then(this.authService.fillAuthData)
            .then(this.getDefaultOrganization)
            .then(this.getSessions)
            .then(() => {
                this.mainHubService.start(); //Initialize Signalr
                let event = new SharedEventModel();
                event.name = SharedEventNameEnum.app_init_successful;
                this.sharedService.broadcast(event);

                //Should show tour?
                this.sessions < 5 ? (this.globals.show_tour = true) : null;

                //Fetch all organizations. Without awaiting for the result
                this.organizationService.getOrganizations();

                this.noOrganizationsCheck();
                if (this.no_organizations) {
                    deferred.resolve(InitializeTypeEnum.new_user_success);
                } else {
                    deferred.resolve(InitializeTypeEnum.success);
                }
            })
            .catch((response) => {
                let val = Object.keys(InitializeTypeEnum).find((k) => InitializeTypeEnum[k] === response);
                if (val) {
                    console.log(`Initialization Failed - ${val}`);
                } else {
                    console.log('Initialization Failed');
                    console.log(JSON.stringify(response, null, 4));
                }
                //Have to give out resolve as otherwise the system doesn't initiate
                deferred.resolve(response);
            })
            .finally(() => {
                //This will trigger router now
                this.is_done = true; //Tells `appLoading` directive to remove the loading screen
            });
        return deferred.promise;
    };

    dummyPromise = () => {
        return Promise.resolve();
    };

    getConfig = () => {
        return this.configService.getConfiguration().then(
            () => {
                return;
            },
            (response: HttpErrorResponse) => {
                console.log(response);
                return Promise.reject(InitializeTypeEnum.app_settings_not_loaded);
            },
        );
    };

    getDefaultOrganization = () => {
        return this.organizationService.getDefaultOrganization().then(
            () => {
                return;
            },
            (response: HttpErrorResponse) => {
                console.log(response);
                return Promise.reject(InitializeTypeEnum.default_organization_error);
            },
        );
    };

    noOrganizationsCheck = () => {
        const organizations_count = this.organizationlistStoreService.getlist().length;
        if (organizations_count < 1) {
            this.no_organizations = true;
        } else {
            this.no_organizations = false;
        }
        return this.no_organizations;
    };

    getSessions = () => {
        return this.userService.getSessions().then((count) => {
            this.sessions = count;
            return count;
        });
    };
}

export function initializeServiceFactory(initializeService: InitializeService): Function {
    return () => initializeService.load();
}
