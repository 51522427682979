export const environment = {
  production: true,
  staging: false,
  dev: false,

  identity_base_url: "https://identity.smarttask.io/",
  auth_base_url: "https://auth-backend.smarttask.io/",
  api_base_url: "https://api-v2.smarttask.io/",
  api2_base_url: "https://api2-v2.smarttask.io/",
  ext_base_url: "https://ext-v2.smarttask.io/",
  app_base_url: "https://v2.smarttask.io/",
  main_base_url: "https://www.smarttask.io/",
  form_base_url: "https://form.smarttask.io/",
  cookie_domain: ".smarttask.io",

  recaptcha: {
    client_key: '6Lcz1UApAAAAAFcnfjtHSknE4LLXnV2uAzNd1Xo4',
  }
};