import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateTime } from "luxon";

@Injectable({
    providedIn: 'root'
})
export class NgbCustomDateParserFormatter extends NgbDateParserFormatter {
    luxon_format = "dd-LLL-yy"; 
    // constructor(private momentFormat: string = "dd-MMM-yy"){
    //     super();
    // }
    parse(value: string): NgbDateStruct {
        if (!value) {
            return undefined;
        }
        let d = DateTime.fromFormat(value, this.luxon_format);
        return d.isValid ? { year: d.year, 
                               month: d.month + 1, 
                               day: d.day } : undefined;
    }

    format(date: NgbDateStruct): string {
        if (date == undefined) {
            return '';
        }
        let d = DateTime.local(date.year, date.month, date.day);
        return d.isValid ? d.toFormat(this.luxon_format) : '';
    }
}