import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'error-main',
    templateUrl: './error.html',
    styleUrls: ['./error.scss'],
    standalone: true,
    imports:[
        CommonModule
    ]
})
export class ErrorComponent {
    title = 'SmartTask Error';
    not_found = false;

    constructor(
    ){
        if (window.location.href.includes("not-found")) {
            this.not_found = true;
        }
        window.history.replaceState({}, "SmartTask Error", "");
    }
}
