import { Injectable } from '@angular/core';
import { SharedService } from '../AngularSpecific/shared.service';
import { MainHubService } from './mainHub.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { OrganizationlistStoreService } from '../Organization/store/organizationlistStore.service';
import * as signalr from '@microsoft/signalr';
import { ViewAccessTypeEnum } from '@smarttask-common/src/lib/models/View/Enum/ViewAccessTypeEnum';
import { ColumnBaseModel } from '@smarttask-common/src/lib/models/Table/ColumnBaseModel';

@Injectable({
    providedIn: 'root',
})
export class ViewHubService {
    private previous_organization_id: number;

    constructor(
        private sharedService: SharedService,
        private mainHubService: MainHubService,
        private organizationlistStoreService: OrganizationlistStoreService,
    ) {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id) => {
            if (this.previous_organization_id != undefined && organization_id == undefined) this.unsubscribe(this.previous_organization_id);
            if (organization_id != undefined) this.subscribeToNewOrganization(organization_id);
        });

        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            let selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id: number) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected) {
            if (this.previous_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.previous_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.previous_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events

        this.mainHubService.connection.on(SharedEventNameEnum.view_create, (view_id: string, project_id: string, user_id: number) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.view_create,
                obj: {
                    view_id,
                    project_id: project_id,
                    user_id: user_id,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.view_update, (view_id: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.view_update,
                obj: {
                    view_id,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.view_name_update, (view_id: string, name: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.view_name_update,
                obj: {
                    view_id,
                    name,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.view_access_update, (view_id: string, access_type: ViewAccessTypeEnum) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.view_access_update,
                obj: {
                    view_id,
                    access_type,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.view_order_update, (view_id: string, order_index: number) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.view_order_update,
                obj: {
                    view_id,
                    order_index,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.view_delete, (view_id: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.view_delete,
                obj: {
                    view_id,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.view_column_upsert, (view_id: string, column_base: ColumnBaseModel[]) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.view_column_upsert,
                obj: {
                    view_id,
                    column_base,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.view_column_delete, (view_id: string, column_id: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.view_column_delete,
                obj: {
                    view_id,
                    column_id,
                },
            };
            this.sharedService.broadcast(event);
        });
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.view_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.view_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };
}
