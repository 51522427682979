export enum GotoTypeEnum {
    default = 'default',
    change_organization = 'change_organization',
    group = 'group',
    project = 'project',
    view = "view",
    user = 'user',
    task = 'task',
    contact = 'contact',
    chat_channel = 'chat_channel',
    discussion = 'discussion',
    payment_result = 'payment_result',
}
