import { coerceElement } from '@angular/cdk/coercion';
import { DragRef, DropListRef } from '@angular/cdk/drag-drop';

// A few lines of code used for debugging (saved to avoid having to re-write them)
// let reflistToString = (list: DropListRef[]) => JSON.stringify(list.map(ref => coerceElement(ref.element).id));
// console.log("Targets: " + reflistToString(targets));

export function installNestedDragAndDropPatch() {
    DropListRef.prototype._getSiblingContainerFromPosition = function (item: DragRef, x: number, y: number): DropListRef | undefined {
        // Hack to access private member DropListRef._siblings
        let this__siblings = (this as any)._siblings as DropListRef[];

        // Possible targets include siblings and this
        let targets: DropListRef<any>[] = [this, ...this__siblings];
        // console.log(targets.map(x =>  [x.element.getBoundingClientRect(), x.data.id]))

        // Only consider targets where the drag postition is within the dom rect
        // (this avoids calling enterPredicate on each possible target)
        let matchingTargets = targets.filter((ref) => {
            // Hack to access private member DropListRef._domRect
            // let ref__domRect = (ref as any)._domRect as DOMRect | undefined;
            let ref__domRect = (ref as any).element.getBoundingClientRect() as DOMRect | undefined;
            return ref__domRect && isInsideDomRect(ref__domRect, x, y);
        });

        // console.log(matchingTargets);

        // Stop if no targets match the coordinates
        if (matchingTargets.length === 0) return undefined;

        // Order candidates by DOM hierarchy and z-index
        let orderedMatchingTargets = orderByHierarchy(matchingTargets);

        // The drop target is the last matching target in the list
        let matchingTarget = orderedMatchingTargets[orderedMatchingTargets.length - 1];

        // console.log(matchingTarget)

        // Return the target if it is a sibling and can recieve the item
        const is_same = matchingTarget == this;
        const can_receive = matchingTarget._canReceive(item, x, y);
        // console.log(can_receive, matchingTarget.data.id);
        if (!is_same && can_receive) {
            return matchingTarget;
        }
        return undefined;
        // return matchingTarget != this && matchingTarget._canReceive(item, x, y) ? matchingTarget : undefined;
    };

    DropListRef.prototype._canReceive = function (item: DragRef, x: number, y: number): boolean {
        // console.log(this)
        let ref__domRect = (this as any).element.getBoundingClientRect() as DOMRect | undefined;
        if (!ref__domRect || !isInsideDomRect(ref__domRect, x, y) || !this.enterPredicate(item, this)) {
            return false;
        }

        const elementFromPoint = this._getShadowRoot().elementFromPoint(x, y) as HTMLElement | null;

        // If there's no element at the pointer position, then
        // the client rect is probably scrolled out of the view.
        if (!elementFromPoint) {
            return false;
        }

        // The `DOMRect`, that we're using to find the container over which the user is
        // hovering, doesn't give us any information on whether the element has been scrolled
        // out of the view or whether it's overlapping with other containers. This means that
        // we could end up transferring the item into a container that's invisible or is positioned
        // below another one. We use the result from `elementFromPoint` to get the top-most element
        // at the pointer position and to find whether it's one of the intersecting drop containers.
        return elementFromPoint === (this as any).element || (this as any).element.contains(elementFromPoint);
    };
}

// Not possible to improt isInsideDomRect from @angular/cdk/drag-drop/dom-rect
function isInsideDomRect(domRect: DOMRect, x: number, y: number) {
    const { top, bottom, left, right } = domRect;
    return y >= top && y <= bottom && x >= left && x <= right;
}

// Order a list of DropListRef so that for nested pairs, the outer DropListRef
// is preceding the inner DropListRef. Should probably be ammended to also
// sort by Z-level.
function orderByHierarchy(refs: DropListRef[]) {
    // Build a map from HTMLElement to DropListRef
    let refsByElement: Map<HTMLElement, DropListRef> = new Map();
    refs.forEach((ref) => {
        refsByElement.set(coerceElement(ref.element), ref);
    });

    // Function to identify the closest ancestor among th DropListRefs
    let findAncestor = (ref: DropListRef) => {
        let ancestor = coerceElement(ref.element).parentElement;

        while (ancestor) {
            if (refsByElement.has(ancestor)) return refsByElement.get(ancestor);

            ancestor = ancestor.parentElement;
        }

        return undefined;
    };

    // Node type for tree structure
    type NodeType = { ref: DropListRef; parent?: NodeType; children: NodeType[] };

    // Add all refs as nodes to the tree
    let tree: Map<DropListRef, NodeType> = new Map();
    refs.forEach((ref) => {
        tree.set(ref, { ref: ref, children: [] });
    });

    // Build parent-child links in tree
    refs.forEach((ref) => {
        let parent = findAncestor(ref);

        if (parent) {
            let node = tree.get(ref);
            let parentNode = tree.get(parent);

            node!.parent = parentNode;
            parentNode!.children.push(node!);
        }
    });

    // Find tree roots
    let roots = [...tree.values()].filter((node) => !node.parent);

    // Function to recursively build ordered list from roots and down
    let buildOrderedList = (nodes: NodeType[], list: DropListRef[]) => {
        list.push(...nodes.map((node) => node.ref));

        nodes.forEach((node) => {
            buildOrderedList(node.children, list);
        });
    };

    // Build and return the ordered list
    let ordered: DropListRef[] = [];
    buildOrderedList(roots, ordered);
    return ordered;
}
