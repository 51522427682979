import { Injectable } from '@angular/core';
import { SharedService } from '../AngularSpecific/shared.service';
import { MainHubService } from './mainHub.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { OrganizationlistStoreService } from '../Organization/store/organizationlistStore.service';
import * as signalr from '@microsoft/signalr';
import { ColumnBaseModel } from '@smarttask-common/src/lib/models/Table/ColumnBaseModel';

@Injectable({
    providedIn: 'root',
})
export class ContactViewHubService {
    private previous_organization_id: number;

    constructor(
        private sharedService: SharedService,
        private mainHubService: MainHubService,
        private organizationlistStoreService: OrganizationlistStoreService,
    ) {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id) => {
            if (this.previous_organization_id != undefined && organization_id == undefined) this.unsubscribe(this.previous_organization_id);
            if (organization_id != undefined) this.subscribeToNewOrganization(organization_id);
        });

        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            let selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id: number) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected) {
            if (this.previous_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.previous_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.previous_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events

        this.mainHubService.connection.on(SharedEventNameEnum.contact_view_upsert, (view_id: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.contact_view_upsert,
                obj: { view_id },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.contact_view_order_update, (view_id: string, order_index: number) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.contact_view_order_update,
                obj: {
                    view_id: view_id,
                    order_index: order_index,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.contact_view_name_update, (view_id: string, name: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.contact_view_name_update,
                obj: {
                    view_id: view_id,
                    name: name,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.contact_view_delete, (view_id: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.contact_view_delete,
                obj: { view_id },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.contact_view_column_upsert, (view_id: string, column_base: ColumnBaseModel) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.contact_view_column_upsert,
                obj: { 
                    view_id: view_id,
                    column_base: column_base 
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.contact_view_column_delete, (view_id: string, column_id: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.contact_view_column_delete,
                obj: {
                    view_id: view_id,
                    column_id: column_id,
                },
            };
            this.sharedService.broadcast(event);
        });
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.chat_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.chat_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };
}
