import { Injectable } from '@angular/core';
import { SharedService } from '../AngularSpecific/shared.service';
import { MainHubService } from './mainHub.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { OrganizationlistStoreService } from '../Organization/store/organizationlistStore.service';
import { DiscussionReactionModel } from '@smarttask-common/src/lib/models/Discussion/Reaction/DiscussionReactionModel';
import * as signalr from '@microsoft/signalr';

@Injectable({
    providedIn: 'root',
})
export class DiscussionsHubService {
    private previous_organization_id: number;

    constructor(
        private sharedService: SharedService,
        private mainHubService: MainHubService,
        private organizationlistStoreService: OrganizationlistStoreService
    ) {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id) => {
            if (this.previous_organization_id != undefined && organization_id == undefined) this.unsubscribe(this.previous_organization_id);
            if (organization_id != undefined) this.subscribeToNewOrganization(organization_id);
        });

        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            let selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id: number) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected)
        {
            if (this.previous_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.previous_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.previous_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events

        this.mainHubService.connection.on(
            SharedEventNameEnum.discussion_new,
            (group_id: string, discussion_id: string, project_id: string = undefined) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.discussion_new;
                event.obj = {
                    group_id,
                    discussion_id,
                    project_id,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(SharedEventNameEnum.discussion_update, (discussion_id: string) => {
            var event = new SharedEventModel();
            event.name = SharedEventNameEnum.discussion_update;
            event.obj = {discussion_id};
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(
            SharedEventNameEnum.discussion_reactions_update,
            (discussion_id: string, reaction: DiscussionReactionModel) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.discussion_reactions_update;
                event.obj = {
                    discussion_id,
                    reaction,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(SharedEventNameEnum.discussion_followers_update, (discussion_id: string) => {
            var event = new SharedEventModel();
            event.name = SharedEventNameEnum.discussion_followers_update;
            event.obj = {
                discussion_id,
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.discussion_delete, (discussion_id: string) => {
            var event = new SharedEventModel();
            event.name = SharedEventNameEnum.discussion_delete;
            event.obj = {
                discussion_id,
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(
            SharedEventNameEnum.discussion_comment_new,
            (discussion_id: string, activity_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.discussion_comment_new;
                event.obj = {
                    discussion_id,
                    activity_id,
                };
                this.sharedService.broadcast(event);
            }
        );
        this.mainHubService.connection.on(
            SharedEventNameEnum.discussion_comment_update,
            (discussion_id: string, activity_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.discussion_comment_update;
                event.obj = {
                    discussion_id,
                    activity_id,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.discussion_comment_reactions_update,
            (discussion_id: string, activity_id: string, reaction: DiscussionReactionModel) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.discussion_comment_reactions_update;
                event.obj = {
                    discussion_id,
                    activity_id,
                    reaction,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.discussion_comment_delete,
            (discussion_id: string, activity_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.discussion_comment_delete;
                event.obj = {
                    discussion_id,
                    activity_id,
                };
                this.sharedService.broadcast(event);
            }
        );
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.discussion_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.discussion_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };
}
