import { RedirectFunction, Router, Routes } from '@angular/router';
import { ErrorComponent } from '../../../smarttask-common/src/lib/components/error/error';
import { AuthorizationComponent } from './area/authorization/authorization';
import { HomeComponent } from './area/home/home';
import { authenticate, homeResolver } from './app.routeFns';
import { GotoTypeEnum } from '@smarttask-common/src/lib/models/GotoTypeEnum';
import { inject } from '@angular/core';
import { LocationService } from './services/location.service';
import { Globals } from '@smarttask-common/src/lib/services/globals';

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        resolve: {
            home: homeResolver,
        },
    },
    {
        path: 'o/:organization_id',
        loadChildren: () => import('./area/organization/organization.routes').then((m) => m.routes),
    },
    {
        path: 'welcome',
        loadComponent: () => import('./area/welcome/main/main').then((c) => c.MainComponent),
    },
    {
        path: 'goto/:organization_id/query',
        redirectTo: <RedirectFunction>goToView,
    },
    { path: 'authorization', component: AuthorizationComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'not-found', component: ErrorComponent },
    { path: '**', component: ErrorComponent }, // Wildcard route for a 404 page
];

export function goToView(redirect_data) {
    console.log(redirect_data)
    const locationService: LocationService = inject(LocationService);
    const router = inject(Router);
    const globals: Globals = inject(Globals);

    const goto_type: GotoTypeEnum = redirect_data.queryParams['type'];
    const organization_id = redirect_data.params['organization_id'];
    const group_id = redirect_data.queryParams['group_id'];
    const project_id = redirect_data.queryParams['project_id'];
    const user_id = redirect_data.queryParams['user_id'];
    const channel_id = redirect_data.queryParams['channel_id'];
    const record_id = redirect_data.queryParams['record_id'];
    const discussion_id = redirect_data.queryParams['discussion_id'];
    let url = '';
    switch (goto_type) {
        case GotoTypeEnum.change_organization:
            locationService.default_location(organization_id);
            break;
        case GotoTypeEnum.group:
            url = locationService.getGroupUrl(organization_id, group_id);
            break;
        case GotoTypeEnum.project:
            url = locationService.getProjectUrl(organization_id, project_id);
            break;
        case GotoTypeEnum.view:
            break;
        case GotoTypeEnum.user:
            url = locationService.getUserUrl(organization_id, user_id);
            break;
        case GotoTypeEnum.task:
            url = locationService.getTaskUrl(organization_id, record_id);
            break;
        case GotoTypeEnum.contact:
            url = locationService.getContactUrl(organization_id, record_id);
            break;
        case GotoTypeEnum.chat_channel:
            url = locationService.getChatChannelUrl(organization_id, channel_id);
            break;
        case GotoTypeEnum.discussion:
            url = locationService.getDiscussionUrl(organization_id, discussion_id);
            break;
        case GotoTypeEnum.payment_result:
            break;

        default:
            url = `/o/${organization_id}`;
            break;
    }

    if(url.includes(globals.app_base_url)) {
        url = url.replace(globals.app_base_url, '/');
    }
    return url;
}
