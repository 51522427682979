import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { AuthInterceptor } from './services/AngularSpecific/auth.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { CustomToastComponent } from './area/customToast/customToast';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { reducers } from './store';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbCustomDateParserFormatter } from '@smarttask-common/src/lib/services/BootstrapSpecific/ngbCustomDateParserFormatter';
import { NgbDateTimeService } from '@smarttask-common/src/lib/services/BootstrapSpecific/ngbDateTime.service';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { InitializeService, initializeServiceFactory } from './services/initialize.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        deps: [InitializeService],
        useFactory: initializeServiceFactory,
        multi: true,
    },
    provideRouter(
        routes, 
        withComponentInputBinding(), 
        withRouterConfig({paramsInheritanceStrategy: 'always'}),
    ),
    provideStore(
        reducers,
        {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: false,
            },
        }
    ),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
    }),
    {
        provide: NgbDateAdapter,
        useClass: NgbDateNativeAdapter, //For conversion from DateStruct to native JS Date
    },
    {
        provide: NgbDateParserFormatter,
        useClass: NgbCustomDateParserFormatter,
    },
    NgbDateTimeService,
    provideToastr({
        positionClass: 'toast-bottom-left',
        toastComponent: CustomToastComponent,
    }),
    provideAngularSvgIcon(),
    provideCharts(withDefaultRegisterables())
  ]
};
