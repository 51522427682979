import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
    selector: '[custom-toast-component]',
    styleUrls: [`./customToast.scss`],
    templateUrl: `./customToast.html`,
    animations: [
        trigger('flyInOut', [
            state(
                'inactive',
                style({
                    display: 'none',
                    opacity: 0,
                }),
            ),
            transition(
                'inactive => active',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            opacity: 0,
                        }),
                        style({
                            opacity: 1,
                        }),
                    ]),
                ),
            ),
            transition(
                'active => removed',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            opacity: 1,
                        }),
                        style({
                            transform: 'translate3d(10%, 0, 0) skewX(10deg)',
                            opacity: 0,
                        }),
                    ]),
                ),
            ),
        ]),
    ],
    preserveWhitespaces: false,
    standalone: true,
    imports: [CommonModule, FormsModule, SvgIconComponent],
})
export class CustomToastComponent extends Toast {
    // constructor is only necessary when not using AoT
    constructor(
        override toastrService: ToastrService,
        override toastPackage: ToastPackage,
    ) {
        super(toastrService, toastPackage);
    }

    action(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction('something');
        return false;
    }

    //For some reason onTap with CustomToast is triggered even when the toast is dismissed on timeout.
    //Because of this issue we have to utilize onCustomTap to trigger action
    //Listen for triggerAction - tap instead of onTap
    onCustomTap = (event) => {
        this.toastPackage.triggerAction('tap');
    };
}
