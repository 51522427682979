import { MainHubService } from '@smarttask-app/src/app/services/SignalRHubs/mainHub.service';
import { Globals } from '@smarttask-common/src/lib/services/globals';

import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from '@angular/common/http';

import { SharedService } from '@smarttask-app/src/app/services/AngularSpecific/shared.service';
import { AuthenticationModel } from '@smarttask-common/src/lib/models/Authentication/AuthenticationModel';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { AuthService } from '@smarttask-app/src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    refreshing_token = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    last_access_token: string = '';
    cross_tab_channel: BroadcastChannel;

    constructor(
        private injector: Injector,
        private cookieService: CookieService,
        private sharedService: SharedService,
        private globals: Globals,
        private mainHubService: MainHubService
    ) {
        this.startBroadcastChannel();
        this.listenForRefreshTokenMessages();
        this.listenForAfterLogout();
    }

    addToken(req: HttpRequest<any>, avoidAddingToken = false): HttpRequest<any> {
        let cookie_value = this.cookieService.get(this.globals.cookie_names.auth);
        let cookie_data: AuthenticationModel = undefined;
        if(cookie_value != undefined && cookie_value.length > 0){
            cookie_data = JSON.parse(cookie_value);
        }
        let session_id = this.mainHubService.connection != undefined ? this.mainHubService.connection.connectionId : undefined;
        // console.log("Session Id:" + session_id);
        if (cookie_data && cookie_data.access_token && !avoidAddingToken) {
            this.last_access_token = cookie_data.access_token;
            if (session_id && session_id.length > 0) {
                return req.clone({
                    headers: req.headers
                        .set('Authorization', 'Bearer ' + cookie_data.access_token)
                        .set('session-id', session_id),
                });
            } else {
                return req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + cookie_data.access_token),
                });
            }
        } else {
            return req.clone();
        }
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        let avoidAddingToken = this.shouldAvoidAddingToken(req);

        return next.handle(this.addToken(req, avoidAddingToken)).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 401:
                            return this.handleAuthError(req, next);
                        default:
                            return this.responseError(error);
                    }
                } else {
                    return this.responseError(error);
                }
            })
        );
    }

    //Check if the authorization is still valid on response from server. If not then redirect back to Login page
    responseError = (error: HttpErrorResponse | any) => {
        //we might use a remote logging infrastructure
        let errMsg: string;
        const temp_error = error.error || '';
        const err = temp_error || JSON.stringify(temp_error);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        if (error instanceof HttpErrorResponse) {
        } else {
            errMsg = error.message ? error.message : error.Message ? error.Message : error.toString();
        }
        console.warn(errMsg);
        return throwError(() => error);
    };

    handleAuthError(req: HttpRequest<any>, next: HttpHandler) {
        let avoidAddingToken = this.shouldAvoidAddingToken(req);
        if (!this.refreshing_token) {
            //Inform other tabs that we are refreshing the token and they need to wait for it to be completed
            this.cross_tab_channel.postMessage('refreshing_token');
            this.refreshing_token = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            let authService = this.injector.get(AuthService);
            return authService.refreshToken().pipe(
                switchMap((data) => {
                    if (data.access_token) {
                        //Inform the subscription that a new access_token is available and it can continue with the requests on hold
                        this.tokenSubject.next(data.access_token);
                        return next.handle(this.addToken(req, avoidAddingToken));
                    } else {
                        return this.logoutUser();
                    }
                }),
                catchError((error) => {
                    console.error(`Refresh Token Failed ` + JSON.stringify(error, null, 4));
                    if(error.error_description != undefined && (<string>error.error_description).includes("has expired")){
                        return this.refreshTokenExpired();
                    }
                    else{
                        // If there is an exception calling 'refreshToken', logout.
                        return this.logoutUser();
                    }
                }),
                finalize(() => {
                    this.refreshing_token = false;
                    this.cross_tab_channel.postMessage('refresh_token_finalized');
                })
            );
        } else {
            return this.tokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => {
                    return next.handle(this.addToken(req, avoidAddingToken));
                })
            );
        }
    }

    shouldAvoidAddingToken = (req: HttpRequest<any>) => {
        let avoidAddingToken = false;
        if (
            !(
                req.url.indexOf(this.globals.api_base_url) > -1 ||
                req.url.indexOf(this.globals.api2_base_url) > -1 ||
                req.url.indexOf(this.globals.ext_base_url) > -1 ||
                req.url.indexOf(this.globals.auth_base_url) > -1
            )
        ) {
            avoidAddingToken = true;
        }
        return avoidAddingToken;
    };

    refreshTokenExpired = () =>{
        let event = new SharedEventModel();
        event.name = SharedEventNameEnum.refresh_token_expired;
        this.sharedService.broadcast(event);
        return throwError(() => new Error('Refresh Token Expired'));
    }
    logoutUser = () => {
        //UnAuthorised
        let event = new SharedEventModel();
        event.name = SharedEventNameEnum.logout;
        this.sharedService.broadcast(event);
        return throwError(() => new Error('Auth Failed'));
    };

    startBroadcastChannel = () => {
        //Starts or joins an existing channel
        //This is utilized to update the other tab when we are refreshing refresh_token
        //This is required as a refresh_token can be utilized only once and if 2 or more tabs together try and utilize the refresh_token it would cause logout
        this.cross_tab_channel = new BroadcastChannel('smarttask_channel');
    };

    //Listens on broadcast channel across windows and tabs
    listenForRefreshTokenMessages = () => {
        this.cross_tab_channel.onmessage = (messageEvent) => {
            switch (messageEvent.data) {
                case 'refreshing_token':
                    this.refreshing_token = true;
                    break;
                case 'refresh_token_finalized':
                    this.refreshing_token = false;
                    this.tokenSubject.next('new_token');
                    break;
            }
        };
    };

    listenForAfterLogout = () => {
        this.sharedService.on(SharedEventNameEnum.after_logout, () => {
            // this.authToken = null;
        });
    };
}
