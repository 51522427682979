import { Injectable } from '@angular/core';
import { SharedService } from '../AngularSpecific/shared.service';
import { MainHubService } from './mainHub.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { OrganizationlistStoreService } from '../Organization/store/organizationlistStore.service';
import { GroupBaseModel } from '@smarttask-common/src/lib/models/Group/Core/GroupBaseModel';
import { GroupUserModel } from '@smarttask-common/src/lib/models/Group/User/GroupUserModel';
import * as signalr from '@microsoft/signalr';

@Injectable({
    providedIn: 'root',
})
export class GroupHubService {
    private previous_organization_id: number;

    constructor(
        private sharedService: SharedService,
        private mainHubService: MainHubService,
        private organizationlistStoreService: OrganizationlistStoreService
    ) {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id) => {
            if (this.previous_organization_id != undefined && organization_id == undefined) this.unsubscribe(this.previous_organization_id);
            if (organization_id != undefined) this.subscribeToNewOrganization(organization_id);
        });

        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            let selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id: number) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected) {
            if (this.previous_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.previous_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.previous_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events
        this.mainHubService.connection.on(SharedEventNameEnum.group_create, (group_id: number) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.group_create,
                obj: {
                    group_id: group_id,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(
            SharedEventNameEnum.group_base_update,
            (group_id: number, group_base: GroupBaseModel) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.group_base_update,

                    obj: {
                        group_id: group_id,
                        group: group_base,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.group_order_update,
            (group_id: number, order_index: number) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.group_order_update,
                    obj: {
                        group_id: group_id,
                        order_index: order_index,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(SharedEventNameEnum.group_delete, (group_id: number) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.group_delete,
                obj: {
                    group_id: group_id,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(
            SharedEventNameEnum.group_users_add,
            (group_id: number, users: Array<GroupUserModel>) => {
                console.log('group_users_add', group_id, users);
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.group_users_add,
                    obj: {
                        group_id: group_id,
                        users: users,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.group_users_role_update,
            (group_id: number, users: Array<GroupUserModel>) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.group_users_role_update,
                    obj: {
                        group_id: group_id,
                        users: users,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.group_user_remove,
            (group_id: number, user_id: number) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.group_user_remove,
                    obj: {
                        group_id: group_id,
                        user_id: user_id,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );
        
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.group_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.group_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };
}
