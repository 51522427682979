import { Injectable } from '@angular/core';
import { SharedService } from '../AngularSpecific/shared.service';
import { MainHubService } from './mainHub.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { OrganizationlistStoreService } from '../Organization/store/organizationlistStore.service';
import { ProjectUserModel } from '@smarttask-common/src/lib/models/ProjectUser/ProjectUserModel';
import { ProjectDetailModel } from '@smarttask-common/src/lib/models/Project/ProjectDetailModel';
import { UpdateIconColorModel } from '@smarttask-common/src/lib/models/General/UpdateIconColorModel';
import { CustomFieldValueModel } from '@smarttask-common/src/lib/models/CustomFieldValue/CustomFieldValueModel';
import * as signalr from '@microsoft/signalr';
import { ProjectDateModel } from '@smarttask-common/src/lib/models/Project/ProjectDateModel';

@Injectable({
    providedIn: 'root',
})
export class ProjectHubService {
    private previous_organization_id: number;

    constructor(
        private sharedService: SharedService,
        private mainHubService: MainHubService,
        private organizationlistStoreService: OrganizationlistStoreService
    ) {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id) => {
            if (this.previous_organization_id != undefined && organization_id == undefined) this.unsubscribe(this.previous_organization_id);
            if (organization_id != undefined) this.subscribeToNewOrganization(organization_id);
        });

        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            let selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id: number) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected) {
            if (this.previous_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.previous_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.previous_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events
        this.mainHubService.connection.on(SharedEventNameEnum.project_create, (project_id: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.project_create,
                obj: project_id,
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_copied,
            (old_project_id: string, new_project_id: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_copied,
                    obj: {
                        old_project_id,
                        new_project_id,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_access_update,
            (project_id: string, access_type: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_access_update,
                    obj: {
                        project_id: project_id,
                        access_type: access_type,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_name_update,
            (project_id: string, name: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_name_update,
                    obj: {
                        project_id: project_id,
                        name: name,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_order_update,
            (project_id: string, order_index: number) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_order_update,
                    obj: {
                        project_id: project_id,
                        order_index: order_index,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_details_update,
            (project_id: string, project_detail: ProjectDetailModel) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_details_update,
                    obj: {
                        project_id: project_id,
                        project_detail: project_detail,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_description_update,
            (project_id: string, description: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_description_update,
                    obj: {
                        project_id: project_id,
                        description: description,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_dates_update,
            (project_id: string, dates: ProjectDateModel) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_dates_update,
                    obj: {
                        project_id: project_id,
                        dates: dates,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_owner_update,
            (project_id: string, owner_user_id: number) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_owner_update,
                    obj: {
                        project_id: project_id,
                        owner_user_id: owner_user_id,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_icon_color_update,
            (project_id: string, project_icon_color: UpdateIconColorModel) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_icon_color_update,
                    obj: {
                        project_id: project_id,
                        project_icon_color: project_icon_color,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_custom_field_value_update,
            (project_id: string, custom_field_value: CustomFieldValueModel) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.project_custom_field_value_update;
                event.obj = {
                    project_id,
                    custom_field_value,
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_users_add,
            (project_id: string, new_users: Array<ProjectUserModel>) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_users_add,
                    obj: {
                        project_id: project_id,
                        new_users: new_users,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_users_role_update,
            (project_id: string, users: Array<ProjectUserModel>) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_users_role_update,
                    obj: {
                        project_id: project_id,
                        users: users,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_users_remove,
            (project_id: string, user_ids: Array<number>) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_users_remove,
                    obj: {
                        project_id: project_id,
                        user_ids: user_ids,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.project_archive_update,
            (project_id: string, archived: boolean) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.project_archive_update,
                    obj: {
                        project_id: project_id,
                        archived: archived,
                    },
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(SharedEventNameEnum.project_delete, (project_id: string) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.project_delete,
                obj: project_id,
            };
            this.sharedService.broadcast(event);
        });
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.project_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.project_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };
}
