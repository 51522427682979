import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@smarttask-app/src/app/services/auth.service';

@Component({
    selector: 'authorization',
    templateUrl: './authorization.html',
    styleUrls: [],
    standalone: true,
    imports:[
        CommonModule
    ]
})
export class AuthorizationComponent implements OnInit{
    code: string;
    state:string;
    access_token: string;
    error:string;
    error_description: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ){

    }

    ngOnInit(){
        this.activatedRoute.queryParams.subscribe(params => {
            this.code = params['code'];
            this.state = params['state'];
            this.access_token = params['access_token'];
            this.error = params['error'];
            this.error_description = params['error_description'];
        });
        if(this.error){
            this.error_description = decodeURIComponent(this.error_description);
        }
        else if(this.access_token){
            this.authService.initializeImpersonatedLogin(this.access_token, this.state);
        }
        else{
            this.authService.getAccessTokenFromAuthorizationCode(this.code, this.state);
        }
    }

}
