<div class="container">
    <div class="row align-items-center justify-content-sm-center">
        <div class="col-md-3 hidden-md">
            <img src="assets/images/common/general-error/penguins1-no-shadow.png" class="turtle" alt="" />
        </div>

        <div class="col-md-9">
            <div class="st-description">
                <h1>Oops!</h1>
                <div class="st-not-found" *ngIf="not_found">
                    <h2><strong>404</strong> Page not found</h2>
                    <p>The page you are looking for does not exist on this server</p>
                </div>
                <div class="st-error" *ngIf="!not_found">
                    <h2>looks like something went completely wrong.</h2>
                </div>
                <p>Please try redirecting to <a href="/">mainpage</a>. If the problem persists contact us.</p>
            </div>
        </div>
    </div>
</div>
