import { Component } from '@angular/core';
import { InitializeService } from './services/initialize.service';
import { AuthService } from './services/auth.service';
import { InitializeTypeEnum } from '@smarttask-common/src/lib/models/Initialize/InitializeTypeEnum';
import { Router, RouterOutlet } from '@angular/router';
import { CustomToastComponent } from './area/customToast/customToast';
import { installNestedDragAndDropPatch } from '@smarttask-common/src/lib/patch/nested-drag-and-drop-patch';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: true,
    imports:[
        RouterOutlet,
        CustomToastComponent,
        DragDropModule
    ]
})
export class AppComponent {

    constructor(
        private initializeService: InitializeService,
        private authService: AuthService,
        private router: Router
    ){

    }

    ngOnInit() {
        this.initializeService.result_promise
            .then((result) => {
                console.log(`Initialization Status - ${result}`);
                switch (result) {
                    case InitializeTypeEnum.new_user_success:
                        this.router.navigate(['/welcome']);
                        break;
                    case InitializeTypeEnum.success:
                        break;
                    case InitializeTypeEnum.auth_data_not_present:
                        //Only trigger authorizationCodeFlow if the url doesn't contain authorization 
                        //as in that case the authorization code flow is ongoing
                        if(!location.href.includes("authorization")){
                            this.authService.triggerAuthorizationFlow();
                        }
                        break;
                    default:
                        this.router.navigate(['/error']);
                        break;
                }
            })
            .catch((error) => {
                this.router.navigate(['/error']);
            });

        installNestedDragAndDropPatch();
    }
}
