import { Component } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss'],
  standalone: true
})
export class HomeComponent {
  
}
