import { Injectable } from '@angular/core';
import { SharedService } from '../AngularSpecific/shared.service';
import { MainHubService } from './mainHub.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { OrganizationlistStoreService } from '../Organization/store/organizationlistStore.service';
import { ChatChannelAccessTypeEnum } from '@smarttask-common/src/lib/models/Chat/Channel/Enums/ChatChannelAccessTypeEnum';
import { ChatChannelCollaboratorModel } from '@smarttask-common/src/lib/models/Chat/Channel/ChatChannelCollaboratorModel';
import * as signalr from '@microsoft/signalr';

@Injectable({
    providedIn: 'root',
})
export class ChatHubService {
    private previous_organization_id: number;

    constructor(
        private sharedService: SharedService,
        private mainHubService: MainHubService,
        private organizationlistStoreService: OrganizationlistStoreService
    ) {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id) => {
            if (this.previous_organization_id != undefined && organization_id == undefined) this.unsubscribe(this.previous_organization_id);
            if (organization_id != undefined) this.subscribeToNewOrganization(organization_id);
        });

        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            let selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id: number) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected)
        {
            if (this.previous_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.previous_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.previous_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events

        this.mainHubService.connection.on(
            SharedEventNameEnum.chat_channel_new,
            (channel_id: string, for_user_ids: number[]) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.chat_channel_new,
                    obj: {
                        channel_id,
                        for_user_ids
                    }
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.chat_channel_name_update,
            (channel_id: string, name: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.chat_channel_name_update;
                event.obj = {
                    channel_id,
                    name
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.chat_channel_access_update,
            (channel_id: string, access_type: ChatChannelAccessTypeEnum) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.chat_channel_access_update;
                event.obj = {
                    channel_id,
                    access_type
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.chat_channel_delete,
            (channel_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.chat_channel_delete;
                event.obj = {channel_id};
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.chat_channel_collaborators_upsert,
            (channel_id: string, users: ChatChannelCollaboratorModel[]) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.chat_channel_collaborators_upsert;
                event.obj = {
                    channel_id,
                    users
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.chat_channel_collaborators_remove,
            (channel_id: string, user_ids: number[]) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.chat_channel_collaborators_remove;
                event.obj = {
                    channel_id,
                    user_ids
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.chat_channel_message_upsert,
            (channel_id: string, message_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.chat_channel_message_upsert;
                event.obj = {
                    channel_id,
                    message_id
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.chat_channel_message_reaction,
            (channel_id: string, message_id: string, by_user_id: number, emoji: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.chat_channel_message_reaction;
                event.obj = {
                    channel_id,
                    message_id,
                    by_user_id,
                    emoji
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.chat_channel_message_delete,
            (channel_id: string, message_id: string) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.chat_channel_message_delete;
                event.obj = {
                    channel_id,
                    message_id
                };
                this.sharedService.broadcast(event);
            }
        );
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.chat_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.chat_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };
}
