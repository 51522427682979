import { Injectable } from '@angular/core';
import { SharedService } from '../AngularSpecific/shared.service';
import { MainHubService } from './mainHub.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { OrganizationlistStoreService } from '../Organization/store/organizationlistStore.service';
import { RecordTypeEnum } from '@smarttask-common/src/lib/models/Record/Main/Enums/RecordTypeEnum';
import * as signalr from '@microsoft/signalr';

@Injectable({
    providedIn: 'root',
})
export class RecordHubService {
    private previous_organization_id: number;

    constructor(
        private sharedService: SharedService,
        private mainHubService: MainHubService,
        private organizationlistStoreService: OrganizationlistStoreService
    ) {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id) => {
            if (this.previous_organization_id != undefined && organization_id == undefined) this.unsubscribe(this.previous_organization_id);
            if (organization_id != undefined) this.subscribeToNewOrganization(organization_id);
        });

        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            let selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id: number) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected)
        {
            if (this.previous_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.previous_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.previous_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events

        this.mainHubService.connection.on(
            SharedEventNameEnum.record_new,
            (record_id: string, project_id: string, user_id: number) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_new,
                    obj: {
                        record_id,
                        project_id: project_id,
                        user_id: user_id
                    }
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.record_update,
            (record_type: RecordTypeEnum, record_id: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_update,
                    obj: {
                        record_type,
                        record_id
                    }
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.record_update_multiple,
            (record_type: RecordTypeEnum, record_ids: string[]) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_update_multiple,
                    obj: {
                        record_type,
                        record_ids
                    }
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.record_delete,
            (record_type: RecordTypeEnum, record_id: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_delete,
                    obj: {
                        record_type,
                        record_id
                    }
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.record_activity_new,
            (record_type: RecordTypeEnum, record_id: string, activity_id: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_activity_new,
                    obj: {
                        record_type,
                        record_id,
                        activity_id
                    }
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.record_activity_update,
            (record_type: RecordTypeEnum, record_id: string, activity_id: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_activity_update,
                    obj: {
                        record_type,
                        record_id,
                        activity_id
                    }
                };
                this.sharedService.broadcast(event);
            }
        );

        this.mainHubService.connection.on(
            SharedEventNameEnum.record_activity_delete,
            (record_type: RecordTypeEnum, record_id: string, activity_id: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_activity_delete,
                    obj: {
                        record_type,
                        record_id,
                        activity_id
                    }
                };
                this.sharedService.broadcast(event);
            });

        //In timeTracking Service
        this.mainHubService.connection.on(
            SharedEventNameEnum.record_estimate_update,
            (record_id: string, estimated_time: number) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_estimate_update,
                    obj: {
                        record_id,
                        estimated_time
                    }
                };
                this.sharedService.broadcast(event);
            });
        
        this.mainHubService.connection.on(
            SharedEventNameEnum.record_billable_update,
            (record_id: string, billable: boolean) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_billable_update,
                    obj: {
                        record_id,
                        billable
                    }
                };
                this.sharedService.broadcast(event);
            });  
        
        this.mainHubService.connection.on(
            SharedEventNameEnum.record_timelog_upsert,
            (record_id: string, actual_time: number, timelog_id: string) => {
                var event: SharedEventModel = {
                    name: SharedEventNameEnum.record_timelog_upsert,
                    obj: {
                        record_id,
                        actual_time,
                        timelog_id
                    }
                };
                this.sharedService.broadcast(event);
            });  
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.record_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.record_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };
}
