import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { memoize } from 'lodash-es';

//declare let chrono;

@Injectable({
    providedIn: 'root'
})
export class NgbDateTimeService {

    constructor(
    ){}

    //ngb-bootstrap datepicker as range picker
    isCurrentDate = memoize((date:NgbDateStruct)=>{
        let temp_date = DateTime.local(date.year, date.month - 1, date.day);
        let current_date = DateTime.local();
        if(temp_date.hasSame(current_date,'day')){
            return true;
        }
        return false;
    })

    isHovered = memoize((date: NgbDateStruct,hovered_date: NgbDateStruct, from_date: Date, to_date: Date) => {
        let temp_date = new Date(date.year,date.month - 1,date.day);
        let processed_hovered_date;
        if(hovered_date){
            processed_hovered_date = new Date(hovered_date.year,hovered_date.month - 1 , hovered_date.day);
        }
        return from_date && !to_date && processed_hovered_date && temp_date > from_date && temp_date < processed_hovered_date;
    },(date:NgbDateStruct, hovered_date: NgbDateStruct, from_date: Date, to_date: Date)=>{
        //Resolver for caching
        let processed_date = new Date(date.year,date.month - 1,date.day).toDateString();
        let processed_hovered_date = hovered_date == undefined ? "" : new Date(hovered_date.year,hovered_date.month - 1 , hovered_date.day).toDateString();
        let processed_from_date = from_date == undefined ? "" : from_date.toISOString();
        let processed_to_date = to_date == undefined ? "" : to_date.toISOString(); 
        return processed_date + "-" + processed_hovered_date + "-" + processed_from_date + "-" + processed_to_date;
    });

    isInside = memoize((date: NgbDateStruct, from_date: Date, to_date: Date) => {
        if(from_date == undefined || to_date == undefined){return false;}
        let temp_date = new Date(date.year,date.month - 1,date.day);
        let processed_from_date = DateTime.fromJSDate(from_date).startOf('day').toJSDate();
        let processed_to_date = DateTime.fromJSDate(to_date).startOf('day').toJSDate();
        let to_ret = temp_date > processed_from_date && temp_date < processed_to_date;
        return to_ret;
    },(date:NgbDateStruct,from_date: Date, to_date: Date)=>{
        //Resolver for caching
        let processed_date = new Date(date.year,date.month - 1,date.day).toDateString();
        let processed_from_date = from_date == undefined ? "" : from_date.toISOString();
        let processed_to_date = to_date == undefined ? "" : to_date.toISOString(); 
        return processed_date + "-" + processed_from_date + "-" + processed_to_date;
    });

    isFrom = memoize((date: NgbDateStruct,from_date: Date) => {        
        if(from_date == undefined){return false;}
        let temp_date = DateTime.local(date.year,date.month - 1,date.day);
        return temp_date.hasSame(DateTime.fromJSDate(from_date), 'day');
    },(date:NgbDateStruct, from_date: Date)=>{
        //Resolver for caching
        let processed_date = new Date(date.year,date.month - 1,date.day).toDateString();
        let processed_from_date = from_date == undefined ? "" : from_date.toISOString();
        return processed_date + "-" + processed_from_date;
    });

    isTo = memoize((date: NgbDateStruct, to_date: Date) => {
        if(to_date == undefined){return false;}
        let temp_date = DateTime.local(date.year,date.month - 1,date.day);
        return temp_date.hasSame(DateTime.fromJSDate(to_date),'day');
    },(date:NgbDateStruct, to_date: Date)=>{
        //Resolver for caching
        let processed_date = new Date(date.year,date.month - 1,date.day).toDateString();
        let processed_to_date = to_date == undefined ? "" : to_date.toISOString(); 
        return processed_date + "-" + processed_to_date;
    });


    getNgbTimeStruct = memoize((date: Date) =>{
        let to_ret = undefined;
        if(date != undefined){
            to_ret = { hour: date.getHours(), 
                minute: date.getMinutes(), 
                second: 0
            };
        }
        return to_ret;
    })
}
